// inset round default

.rounded.inset-round {
    background:
        -moz-radial-gradient(0 100%, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px),
        -moz-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px),
        -moz-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px),
        -moz-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px);
    background:
        -o-radial-gradient(0 100%, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px),
        -o-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px),
        -o-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px),
        -o-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px);
    background:
        -webkit-radial-gradient(0 100%, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px),
        -webkit-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px),
        -webkit-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px),
        -webkit-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px);
    background-position: bottom left, bottom right, top right, top left;
    -moz-background-size: 51% 51%;
    -webkit-background-size: 51% 51%;
    background-size: 51% 51%;
    background-repeat: no-repeat;
}

.rounded.inset-round-s {
    background:
        -moz-radial-gradient(0 100%, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px),
        -moz-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 0, #EFF5FE 0),
        -moz-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 0, #EFF5FE 0),
        -moz-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px);
    background:
        -o-radial-gradient(0 100%, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px),
        -o-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 0, #EFF5FE 0),
        -o-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 0, #EFF5FE 0),
        -o-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px);
    background:
        -webkit-radial-gradient(0 100%, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px),
        -webkit-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 0, #EFF5FE 0),
        -webkit-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 0, #EFF5FE 0),
        -webkit-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px);
    background-position: bottom left, bottom right, top right, top left;
    -moz-background-size: 51% 51%;
    -webkit-background-size: 51% 51%;
    background-size: 51% 51%;
    background-repeat: no-repeat;
}

.rounded.inset-round-e {
    background:
        -moz-radial-gradient(0 100%, circle, rgba(204, 0, 0, 0) 0, #EFF5FE 0),
        -moz-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px),
        -moz-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px),
        -moz-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 0, #EFF5FE 0);
    background:
        -o-radial-gradient(0 100%, circle, rgba(204, 0, 0, 0) 0, #EFF5FE 0),
        -o-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px),
        -o-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px),
        -o-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 0, #EFF5FE 0);
    background:
        -webkit-radial-gradient(0 100%, circle, rgba(204, 0, 0, 0) 0, #EFF5FE 0),
        -webkit-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px),
        -webkit-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 14px, #EFF5FE 15px),
        -webkit-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 0, #EFF5FE 0);
    background-position: bottom left, bottom right, top right, top left;
    -moz-background-size: 51% 51%;
    -webkit-background-size: 51% 51%;
    background-size: 51% 51%;
    background-repeat: no-repeat;
}

$colors: (
    "primary": #7397D1,
    "success": #4BC38C,
    "warning": #FDB64D,
    "danger": #d70000,
    "brand": #6B5EC0,
);

@each $color, $i in $colors {
    .rounded.inset-round-#{$color} {
        background:
            -moz-radial-gradient(0 100%, circle, rgba(204, 0, 0, 0) 8px, $i 8px),
            -moz-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 8px, $i 8px),
            -moz-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 8px, $i 8px),
            -moz-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 8px, $i 8px);
        background:
            -o-radial-gradient(0 100%, circle, rgba(204, 0, 0, 0) 8px, $i 8px),
            -o-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 8px, $i 8px),
            -o-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 8px, $i 8px),
            -o-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 8px, $i 8px);
        background:
            -webkit-radial-gradient(0 100%, circle, rgba(204, 0, 0, 0) 8px, $i 8px),
            -webkit-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 8px, $i 8px),
            -webkit-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 8px, $i 8px),
            -webkit-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 8px, $i 8px);
        background-position: bottom left, bottom right, top right, top left;
        -moz-background-size: 52% 52%;
        -webkit-background-size: 52% 52%;
        background-size: 52% 52%;
        background-repeat: no-repeat;
        color: white !important;
    }

    .rounded.inset-round-#{$color}-s {
        background:
            -moz-radial-gradient(0 100%, circle, rgba(204, 0, 0, 0) 8px, $i 8px),
            -moz-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 0, $i 0),
            -moz-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 0, $i 0),
            -moz-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 8px, $i 8px);
        background:
            -o-radial-gradient(0 100%, circle, rgba(204, 0, 0, 0) 8px, $i 8px),
            -o-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 0, $i 0),
            -o-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 0, $i 0),
            -o-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 8px, $i 8px);
        background:
            -webkit-radial-gradient(0 100%, circle, rgba(204, 0, 0, 0) 8px, $i 8px),
            -webkit-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 0, $i 0),
            -webkit-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 0, $i 0),
            -webkit-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 8px, $i 8px);
        background-position: bottom left, bottom right, top right, top left;
        -moz-background-size: 52% 52%;
        -webkit-background-size: 52% 52%;
        background-size: 52% 52%;
        background-repeat: no-repeat;
        color: white !important;
    }

    .rounded.inset-round-#{$color}-e {
        background:
            -moz-radial-gradient(0 100%, circle, rgba(204, 0, 0, 0) 0, $i 0),
            -moz-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 8px, $i 8px),
            -moz-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 8px, $i 8px),
            -moz-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 0, $i 0);
        background:
            -o-radial-gradient(0 100%, circle, rgba(204, 0, 0, 0) 0, $i 0),
            -o-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 8px, $i 8px),
            -o-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 8px, $i 8px),
            -o-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 0, $i 0);
        background:
            -webkit-radial-gradient(0 100%, circle, rgba(204, 0, 0, 0) 0, $i 0),
            -webkit-radial-gradient(100% 100%, circle, rgba(204, 0, 0, 0) 8px, $i 8px),
            -webkit-radial-gradient(100% 0, circle, rgba(204, 0, 0, 0) 8px, $i 8px),
            -webkit-radial-gradient(0 0, circle, rgba(204, 0, 0, 0) 0, $i 0);
        background-position: bottom left, bottom right, top right, top left;
        -moz-background-size: 52% 52%;
        -webkit-background-size: 52% 52%;
        background-size: 52% 52%;
        background-repeat: no-repeat;
        color: white !important;
    }
}