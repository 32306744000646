.node-graphic {
    height: 120px; 
    width: 120px; 
    object-fit: contain; 
    object-position: center;
    position: absolute;
}

.node-body {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: 110px;
}

.node-text {
    max-width: 100px;
    max-height: 100px;
}