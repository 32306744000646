.input-group-text {
    background-color: #fff;
    padding: inherit;
    border-left: none;
}

.cdk-overlay-container {
    position: fixed;
    z-index: 1100;
}

.modal {
    .form-control {
        background-color: #f2f2f2;
        border: transparent;
    }

    .input-group-text {
        background-color: #f2f2f2;
        padding: inherit;
        border: transparent;
    }
}