a {
    text-decoration: none !important;
    color: black !important;
    cursor: pointer;
}

li {
    list-style: none;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-default {
    cursor: default !important;
}

.cursor-move {
    cursor: move !important;
}

.topbar {

    display: flex;
    justify-content: flex-end;
    background-color: var(--topbar);
    height: 5vh;
    padding-top: 10px;
    margin-top: 10px;

    ul {
        display: flex;
        margin-right: 20px;

    }

    li {
        margin: 0px 10px 0px 10px;

        .scale {
            &:hover {
                // transform: scale(1.1);
            }
        }
    }

    img {
        height: 2vh;
        width: 2vh;
        opacity: 0.6;
    }
}

.w-20 {
    width: 20% !important;
}

.w-80 {
    width: 80% !important;
}

.image-container {
    object-fit: cover;
    object-position: center;
}

.login-logo-img {
    height: 300px;
    object-fit: cover;
    transform: scale(1.2, 1.2);
    z-index: -1;
}

.bg-supervisor {
    background-color: #7A2EB6;
    color:white;
 }
 
.text-spv {
  color: #7A2EB6;
 }
 
.bg-subordinate {
    background-color: #2EB66D;
    color:white;
}
 
.text-sub {
    color: #2EB66D;
}

.btn-mobile-block { 
    @media (max-width:600px) {
        width: 100% !important; 
        display: block; 
    }
}

.btn-mobile {
    @media (max-width:600px) {
        width: 30px !important; 
    }
}

.filter-primary {
    filter: invert(34%) sepia(36%) saturate(1932%) hue-rotate(190deg) brightness(70%) contrast(90%);
}

// PRIMARY COLOR

.btn-primary {
    background-color: #2E62B6 !important;
    color: #ffffff;
}

.btn-primary:hover {
    background-color: #1f88c5 !important;
}

.btn-outline-primary {
    background-color: #ffffff !important;
    color: #2E62B6;
}

.btn-outline-primary:hover {
    border-color: #1f88c5 !important;
    background-color: #ffffff !important;
    color: #1f88c5;
}

.bg-primary {
    background-color: #2E62B6 !important;
}

.bg-primary2 {
    background-color: #E2ECF8 !important;
}

.bg-primary3 {
    background-color: #769AD1 !important;
    color: white !important;
}

.bg-outline-primary {
    background-color: #fefefe !important;
    border-color: #2E62B6 !important;
    color: #2E62B6 !important;
}

.text-primary {
    color: #2E62B6 !important;
}

.text-primary2 {
    color: #0076B6 !important;
}

// INFO COLOR

.btn-info3 {
    background-color: #E2ECF8 !important;
    color: #2E62B6 !important;
}

.btn-info3:hover {
    background-color: #2E62B6 !important;
    color: #E2ECF8 !important;
}

.bg-info2 {
    background-color: #f3f8ff !important;
}

.bg-info3 {
    background-color: #E2ECF8 !important;
}

// SUCCESS COLOR

.bg-success2 {
    background-color: #e7f6f2;
}

.bg-success3 {
    background-color: #4BC38C !important;
    color: white;
}

// WARNING COLOR

.bg-warning2 {
    background-color: #FFE0B277;
}

.bg-warning3 {
    background-color: #FDB64D;
    color: white;
}

// DANGER COLOR

.btn-danger {
    background-color: #C73316;
    border-color: #C73316;
    color: #ffffff;
}

.btn-danger:hover {
    background-color: #F96868;
    border-color: #F96868;
    color: #ffffff;
}

.btn-outline-danger {
    border-color: #C73316;
    background-color: #ffffff;
    color: #C73316;
}

.btn-outline-danger:hover {
    border-color: #F96868;
    background-color: #ffffff;
    color: #F96868;
}

.bg-danger2 {
    background-color: #fff3f3;
}

.bg-danger2a {
    background-color: #fff3f3;
}

.bg-danger3 {
    background-color: #FF6565;
    color: white;
}

// LIGHT COLOR

.bg-light2 {
    background-color: #f6f6f6;
}

.bg-light3 {
    background-color: #f8f9fa !important;
}

// BRAND COLOR

.text-brand {
    color: #6B5EC0 !important;
}

.bg-brand {
    background-color: #6B5EC0;
    color: #f9f9f9 !important;
}

.bg-brand2 {
    background-color: #8691C7;
    color: #f9f9f9 !important;
}

.bg-brand3 {
    background-color: #6B5EC0;
    color: #f9f9f9 !important;
}

// BLUE COLOR

.bg-blue {
    background-color: #0289d1b7;
    border: transparent;
    color: #f9f9f9;
}

.bg-blue2 {
    background-color: #0289d13b;
    border: transparent;
    color: #f9f9f9;
}

// GREEN COLOR

.btn-green {
    background-color: #2EB66D;
    color: #f9f9f9;
}

.btn-green:hover {
    background-color: #339360;
    color: #f9f9f9;
}

.bg-green {
    background-color: #2EB66D;
    color: #f9f9f9;
}

// PURPLE COLOR

.btn-purple {
    background-color: #C300F3;
    color: #f9f9f9;
}

.btn-purple:hover {
    background-color: #9101b5;
    color: #f9f9f9;
}

.bg-purple {
    background-color: #C300F3;
    color: #f9f9f9;
}

.bg-pink {
    background-color: #E1329B;
    color: #f9f9f9;
}

.border-grey {
    border-color: #CBCBCB !important;
}

.shadow-custom {
    border: none;
    box-shadow: 2px 4px 12px 12px rgba(130, 169, 199, 0.08);
}

.shadow-custom-blue {
    box-shadow: 0px 5px 20px 0 #369eff60;
}

.border-transparent {
    border-color: transparent;
}

.border-dashed {
    border-style: dashed;
}

// INPUT

input.form-control:read-only {
    background-color: #f3f8ff;
}

input[type="file"]:read-only {
    background-color: #f2f2f2;
}

textarea.form-control:read-only {
    background-color: #f3f8ff;
}

table {
    .title-cell {
        width: 300px;

        @media (max-width: 600px) {
            width: 100% !important;
        }
    }

    .content-cell {
        min-width: 300px;
        height: 60px;

        @media (max-width: 600px) {
            width: 100% !important;
        }
    }

    .action-cell {
        min-width: 200px;
        width: 300px;
        @media (max-width: 600px) {
            width: 100% !important;
            height: 100px;
        }
    }
}

.overflow-mobile-auto {
    @media (max-width:720px) {
        overflow: auto;
    }
}

.icon-bold {
    -webkit-text-stroke: 1px;
}

.image-holder {
    position: relative;
}

.image-holder:hover .overlay {
    opacity: 1;
    background-color: #00000060;
    bottom: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.overlay {
    transition: .3s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    background-color: #00000060;
}

@media (min-width: 992px) {
    .modal-lg {
      --bs-modal-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xxl {
      --bs-modal-width: 1140px;
    }
}

.modal-xxl {
    --bs-modal-width: 1600px;
}